import React, { useEffect, useState } from 'react'
import siteLogo from './../../assets/images/logo.jpeg'
import { Link, useNavigate } from 'react-router-dom'
import { login } from '../../store/actions/authAction'
import { useDispatch, useSelector } from 'react-redux'
import { Tokens } from '../../constants'

const SignIn = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLoading } = useSelector(state => state)
  const [error, setError] = useState({});
  const token = localStorage.getItem(Tokens.USER_TOKEN)
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
  })

  useEffect(() => {
    if (token) {
      navigate("/editor")
    }
  }, [navigate, token]);

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
  }

  const handleValidation = () => {
    let errors = {};
    let isValid = true;
    if (userInfo.email === '') {
      errors['email'] = 'Please enter your email';
      isValid = false;
    }
    if (userInfo.password === '') {
      errors['password'] = 'Please enter a password';
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    if (handleValidation()) {
      dispatch(login(userInfo, navigate))
    }
  }

  return (
    <main className="main" id="top">
      <div className="container">
        <div className="row flex-center min-vh-100 py-5">
          <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3"><Link className="d-flex flex-center text-decoration-none mb-4" to={"/editor"}>
            <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block avatar avatar-xl">
            <img className='rounded-soft' src={siteLogo} alt="phoenix" width={58} />
            </div>
          </Link>
            <div className="text-center mb-7">
              <h3 className="text-1000">Login</h3>
              {/* <p className="text-700">Get access to your account</p> */}
            </div>
            {/* <button className="btn btn-phoenix-secondary w-100 mb-3"><span className="fab fa-google text-danger me-2 fs--1" />Sign in with google</button>
            <button className="btn btn-phoenix-secondary w-100"><span className="fab fa-facebook text-primary me-2 fs--1" />Sign in with facebook</button> */}
            <div className="position-relative">
              <hr className="bg-200 mt-5 mb-4" />
              {/* <div className="divider-content-center">or use email</div> */}
            </div>
            <div className='needs-validation'>
              <div className="mb-3 text-start">
                <label className="form-label" for="email">Email address</label>
                <input
                  className={`form-control ${error['email'] && 'is-invalid'}`}
                  // className="form-control" 
                  id="email" type="email" name='email' required placeholder="name@example.com" onChange={(e) => handleChange(e)} />
                <div className={error['email'] ? "invalid-feedback" : 'valid-feedback'}>{error['email'] ? error['email'] : null}</div>
              </div>
              <div className="mb-3 text-start">
                <label className="form-label" for="password">Password</label>
                <input
                  className={`form-control shadow-none ${error['password'] && 'is-invalid'}`}
                  // className="form-control form-icon-input"
                  autoComplete='off' id="password" name='password' required type="password" placeholder="Password" onChange={(e) => handleChange(e)} />
                <div className={error['password'] ? "invalid-feedback" : 'valid-feedback'}>{error['password'] ? error['password'] : null}</div>
              </div>
              <div className="row flex-between-center mb-7">
                <div className="col-auto">
                  <div className="form-check mb-0">
                    <input className="form-check-input" id="basic-checkbox" type="checkbox" defaultChecked="checked" />
                    <label className="form-check-label mb-0" htmlFor="basic-checkbox">Remember me</label>
                  </div>
                </div>
                <div className="col-auto"><Link className="fs--1 fw-semi-bold" to={"/forgot-password"}>Forgot Password?</Link></div>
              </div>
              <button className="btn btn-primary w-100 mb-3" onClick={(e) => handleSubmit(e)}>{isLoading ?
                <><span class="spinner-border spinner-border-sm" style={{ width: '15px', height: '15px' }} role="status" aria-hidden="true"></span> Sign In </> : 'Sign In'}</button>
              <div className="text-center"><Link className="fs--1 fw-bold" to={"/register"}>Create an account</Link></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default SignIn