import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { Tokens } from "./constants";
import { route } from "./routes";

function UserApp() {
    const token = localStorage.getItem(Tokens.USER_TOKEN);
    const prepareRoutes = () => {
        let filterRoutes = [];
        route.forEach((route) => {
            if (route.permission === '') {
                filterRoutes.push(route)
            }
        });
        return filterRoutes;
    };

    const routes = prepareRoutes();
    return (
            <Routes>
                {routes.map((route, index) => {
                    return route.ele ? (
                        <Route key={index} exact={true} path={route.path} element={token ? route.ele : <Navigate replace to={"/login"} />  } />
                    ) : (null)
                })}
                <Route path='*' element={<Navigate replace to={"/"} />} />
            </Routes>
    )
}

export default UserApp;
