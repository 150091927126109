export const handleScaling = (title) => {
  const textScaleX = title.scaleX;
  const actualTextWidth = title.calcTextWidth() * textScaleX;
  const textBoxWidth = title.width * textScaleX;

  if (textBoxWidth < actualTextWidth) {
    const scaleFactor = textBoxWidth / actualTextWidth;
    title.set('fontSize', title.fontSize * scaleFactor);
  }
};


// Implement a word wrapping function
export const wordWrap = (text, maxLineWidth) => {
  const words = text.split(' ');
  const lines = [];
  let line = '';

  while (words.length > 0) {
    const word = words.shift();
    const testLine = `${line}${word} `;

    if (testLine.length > maxLineWidth && line !== '') {
      lines.push(line);
      line = `${word} `;
    } else {
      line = testLine;
    }
  }

  lines.push(line);

  return lines.join('\n');
};