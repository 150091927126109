import {toastifyType} from '../../constants';
const initialState = {
    toastType: null,
    toastMessage: '',
  };
  
  const toastReducer = (state = initialState, action) => {
    switch (action.type) {
      case toastifyType.SHOW_TOAST:
        return {
          ...state,
          toastType: action.payload.type,
          toastMessage: action.payload.message,
        };
      default:
        return state;
    }
  };
  
  export default toastReducer;
  