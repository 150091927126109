// DynamicAssets.js
import { dynamicAssets } from './config';
import { dynamicThemeAssets } from './themConfig';

export const cssAssets = () => {

  dynamicAssets.forEach(asset => {
    // const portal = document.getElementsByTagName('body')[0]
    // const headPortal = document.getElementsByTagName('head')[0]
    const portal = document.body;
    const headPortal = document.head;
    let getElement = document.getElementById(asset.id)
    const element = document.createElement(asset.type);
    if (getElement && asset.isLoad === true) {
      getElement.remove();
    }
    for (const key in asset) {
      if (key !== 'type') {
        // element.setAttribute(key, asset[key]);
        // do not remove commit 
        if (getElement && asset.isLoad === true) {
          if (asset.isHead === true) {
            element.setAttribute(key, asset[key]);
          } else {
            element.setAttribute(key, asset[key]);
          }
        } else {
          if (getElement === null) {
            if (asset.isHead === true) {
              element.setAttribute(key, asset[key]);
            } else {
              element.setAttribute(key, asset[key]);
            }
          }
        }
      }
    }
    if (getElement === null) {
      if (asset.isHead === true) {
        headPortal.appendChild(element);
      } else {
        portal.appendChild(element);
      }
    }

  });

  return null;
}

