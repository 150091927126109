import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import siteLogo from './../../assets/images/logo.jpeg'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../store/actions/authAction'



const SignUp = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {isLoading} = useSelector(state => state)
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    password: '',
    confirm_password: ''
  })
  const [error, setError] = useState({});

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
    setError({})
  }

  const handleValidation = () => {
    let errors = {};
    let isValid = true;

    if (userInfo.name === '') {
      errors['name'] = 'Please enter your name';
      isValid = false;
    }

    if (userInfo.email === '') {
      errors['email'] = 'Please enter your email';
      isValid = false;
    }

    if (userInfo.password === '') {
      errors['password'] = 'Please enter a password';
      isValid = false;
    }

    if (userInfo.confirm_password === '') {
      errors['confirm_password'] = 'Please enter confirm password';
      isValid = false;
    } else if (userInfo.password !== userInfo.confirm_password) {
      errors['confirm_password'] = 'Passwords do not match';
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    if(handleValidation()){
      dispatch(register(userInfo, navigate))
    }
  }

  return (
    <main className="main" id="top">
      <div className="container">
        <div className="row flex-center min-vh-100 py-5">
          <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3"><Link className="d-flex flex-center text-decoration-none mb-4" to={'/editor'}>
            <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block avatar avatar-xl">
            <img src={siteLogo} className='rounded-soft' alt="phoenix" width={58} />
            </div>
          </Link>
            <div className="text-center mb-7">
              <h3 className="text-1000">Register</h3>
              {/* <p className="text-700">Create your account today</p> */}
            </div>
            {/* <button className="btn btn-phoenix-secondary w-100 mb-3"><span className="fab fa-google text-danger me-2 fs--1" />Sign up with google</button>
            <button className="btn btn-phoenix-secondary w-100"><span className="fab fa-facebook text-primary me-2 fs--1" />Sign up with facebook</button> */}
            <div className="position-relative mt-4">
              <hr className="bg-200" />
              {/* <div className="divider-content-center">or use email</div> */}
            </div>
            <div className='needs-validation'>
              <div className="mb-3 text-start">

                <label className="form-label" for="name">Name</label>
                <input 
                className={`form-control shadow-none ${error['name'] && 'is-invalid'}`}
                // className="form-control " 
                id="name" type="text" name="name" placeholder="Name" onChange={(e) => handleChange(e)} />
                <div className={error['name'] ? "invalid-feedback" : ''}>{error['name'] ? error['name'] : null}</div>
              </div>
              <div className="mb-3 text-start">
                <label className="form-label" for="email">Email address</label>
                <input 
                className={`form-control shadow-none ${error['email'] && 'is-invalid'}`}
                // className="form-control" 
                id="email" type="email" name='email' required placeholder="name@example.com" onChange={(e) => handleChange(e)} />
                <div className={error['email'] ? "invalid-feedback" : 'valid-feedback'}>{error['email'] ? error['email'] : null}</div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-6">
                  <label className="form-label" for="password">Password</label>
                  <input 
                  className={`form-control shadow-none ${error['password'] && 'is-invalid'}`}
                  // className="form-control form-icon-input"
                   autoComplete='off' id="password" name='password' required type="password" placeholder="Password" onChange={(e) => handleChange(e)} />
                  <div className={error['password'] ? "invalid-feedback" : 'valid-feedback'}>{error['password'] ? error['password'] : null}</div>
                </div>
                <div className="col-sm-6">
                  <label className="form-label" for="confirm_password">Confirm Password</label>
                  <input 
                  className={`form-control shadow-none ${error['confirm_password'] && 'is-invalid'}`}
                  // className="form-control form-icon-input" 
                  autoComplete='off' id="confirm_password" name='confirm_password' required type="password" placeholder="Confirm Password" onChange={(e) => handleChange(e)} />
                  <div className={error['confirm_password'] ? "invalid-feedback" : 'valid-feedback'}>{error['confirm_password'] ? error['confirm_password'] : null}</div>
                </div>
              </div>
              <div className="form-check mb-3">
                <input 
                // className={`form-control shadow-none ${error['name'] && 'is-invalid'}`}
                className="form-check-input" 
                id="termsService" type="checkbox" />
                <label className="form-label fs--1 text-none" htmlFor="termsService">I accept the <Link to={"#!"}>terms </Link>and <Link to={"#!"}>privacy policy</Link></label>
                {/* <div className="invalid-feedback">Please provide a valid zip.</div> */}
              </div>
              <button className="btn btn-primary w-100 mb-3" type="submit" onClick={(e) => handleSubmit(e)}>{isLoading ?   
                <><span class="spinner-border spinner-border-sm" role="status" style={{width: '15px', height: '15px'}} aria-hidden="true"></span> Sign up </>: 'Sign up'}</button>
              <div className="text-center"><Link className="fs--1 fw-bold" to={"/login"}>Sign in to an existing account</Link></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default SignUp