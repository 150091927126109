import { environment } from "../config/environment";

// config.js
export const dynamicAssets = [
  // {
  //   type: 'link',
  //   rel: 'stylesheet',
  //   href: environment.BASE_URL +'/theme/css/simplebar.css',
  //   id: 'simplebarCss',
  //   isLoad: false,
  //   isHead: true
  // },
  // {
  //   type: 'link',
  //   rel: 'stylesheet',
  //   href: environment.BASE_URL +'/theme/css/theme.css',
  //   id: 'theme',
  //   isLoad: false,
  //   isHead: true
  // },
  // {
  //   type: 'link',
  //   rel: 'stylesheet',
  //   href: environment.BASE_URL +'/theme/css/user.css',
  //   id: 'user',
  //   isLoad: false,
  //   isHead: true
  // },
  {
    type: 'script',
    src: environment.BASE_URL +'/theme/js/imagesloaded.pkgd.min.js',
    id: 'imagesloaded',
    isLoad: false,
    isHead: true
  },
  {
    type: 'script',
    src: environment.BASE_URL +'/theme/js/simplebar.min.js',
    id: 'simplebar',
    isLoad: true,
    isHead: true
  },
  {
    type: 'script',
    src: environment.BASE_URL +'/theme/js/config.js',
    id: 'config',
    isLoad: true,
    isHead: true
  },
  {
    type: 'script',
    src: environment.BASE_URL +'/theme/vendors/popper/popper.min.js',
    id: 'popper',
    isLoad: false,
    isHead: false
  },

  {
    type: 'script',
    src: environment.BASE_URL +'/theme/vendors/bootstrap/bootstrap.min.js',
    id: 'bootstrap',
    isLoad: false,
    isHead: false
  },

  {
    type: 'script',
    src:environment.BASE_URL + '/theme/vendors/anchorjs/anchor.min.js',
    id: 'anchor',
    isLoad: false,
    isHead: false
  },
  {
    type: 'script',
    src: environment.BASE_URL +'/theme/vendors/is/is.min.js',
    id: 'is',
    isLoad: false,
    isHead: false
  },
  {
    type: 'script',
    src: environment.BASE_URL +'/theme/vendors/fontawesome/all.min.js',
    id: 'all',
    isLoad: false,
    isHead: false
  },
  {
    type: 'script',
    src: environment.BASE_URL + '/theme/vendors/lodash/lodash.min.js',
    id: 'lodash',
    isLoad: true,
    isHead: false
  },
  {
    type: 'script',
    src: 'https://polyfill.io/v3/polyfill.min.js?features=window.scroll',
    id: 'scroll',
    isLoad: false,
    isHead: false
  },
  {
    type: 'script',
    src: environment.BASE_URL +'/theme/vendors/list.js/list.min.js',
    id: 'list',
    isLoad: true,
    isHead: false
  },
  {
    type: 'script',
    src: environment.BASE_URL +'/theme/vendors/feather-icons/feather.min.js',
    id: 'feather',
    isLoad: true,
    isHead: false
  },

  {
    type: 'script',
    src: environment.BASE_URL +'/theme/vendors/dayjs/dayjs.min.js',
    id: 'dayjs',
    isLoad: false,
    isHead: false
  },
  {
    type: 'script',
    src:environment.BASE_URL + '/theme/js/phoenix.js',
    id: 'phoenix',
    isLoad: true,
    isHead: false
  },
  // Add more assets as needed
];
// import { environment } from "../config/environment";
// import imagesloaded from 'imagesloaded/imagesloaded.pkgd.js'
// import simplebar from 'simplebar/src/index.js'
// import popperjs from '@popperjs/core'
// import bootstrap from 'bootstrap/dist/js/bootstrap.js'
// import anchor from 'anchor-js/anchor.js'
// import is_js from 'is_js/is.js'
// import fortawesome from '@fortawesome/fontawesome-free/js/all.js'
// import fortawesome from '@fortawesome/fontawesome-free/js/all.js'
// import lodash from 'lodash/lodash.js'
// import list from 'list.js/dist/list.js'
// import feather from 'feather-icons/dist/feather.js'
// import dayjs from 'dayjs'


// // config.js
// export const dynamicAssets = [
//   {
//     type: 'script',
//     src: imagesloaded,
//     id: 'imagesloaded',
//     isLoad: false,
//     isHead: true
//   },
//   {
//     type: 'script',
//     src: simplebar,
//     id: 'simplebar',
//     isLoad: true,
//     isHead: true
//   },
//   {
//     type: 'script',
//     src: environment.BASE_URL +'/theme/js/config.js',
//     id: 'config',
//     isLoad: true,
//     isHead: true
//   },
//   {
//     type: 'link',
//     rel: 'stylesheet',
//     href: environment.BASE_URL +'/theme/css/simplebar.css',
//     id: 'simplebarCss',
//     isLoad: false,
//     isHead: true
//   },
//   {
//     type: 'link',
//     rel: 'stylesheet',
//     href: environment.BASE_URL +'/theme/css/theme.css',
//     id: 'theme',
//     isLoad: false,
//     isHead: true
//   },
//   {
//     type: 'link',
//     rel: 'stylesheet',
//     href: environment.BASE_URL +'/theme/css/user.css',
//     id: 'user',
//     isLoad: false,
//     isHead: true
//   },

//   {
//     type: 'script',
//     src: environment.BASE_URL +'/theme/vendors/popper/popper.min.js',
//     id: 'popper',
//     isLoad: false,
//     isHead: false
//   },

//   {
//     type: 'script',
//     src: environment.BASE_URL +'/theme/vendors/bootstrap/bootstrap.min.js',
//     id: 'bootstrap',
//     isLoad: false,
//     isHead: false
//   },

//   {
//     type: 'script',
//     src:environment.BASE_URL + '/theme/vendors/anchorjs/anchor.min.js',
//     id: 'anchor',
//     isLoad: false,
//     isHead: false
//   },
//   {
//     type: 'script',
//     src: environment.BASE_URL +'/theme/vendors/is/is.min.js',
//     id: 'is',
//     isLoad: false,
//     isHead: false
//   },
//   {
//     type: 'script',
//     src: environment.BASE_URL +'/theme/vendors/fontawesome/all.min.js',
//     id: 'all',
//     isLoad: false,
//     isHead: false
//   },
//   {
//     type: 'script',
//     src: environment.BASE_URL + '/theme/vendors/lodash/lodash.min.js',
//     id: 'lodash',
//     isLoad: true,
//     isHead: false
//   },
//   {
//     type: 'script',
//     src: 'https://polyfill.io/v3/polyfill.min.js?features=window.scroll',
//     id: 'scroll',
//     isLoad: true,
//     isHead: false
//   },
//   {
//     type: 'script',
//     src: environment.BASE_URL +'/theme/vendors/list.js/list.min.js',
//     id: 'list',
//     isLoad: false,
//     isHead: false
//   },
//   {
//     type: 'script',
//     src: environment.BASE_URL +'/theme/vendors/feather-icons/feather.min.js',
//     id: 'feather',
//     isLoad: false,
//     isHead: false
//   },

//   {
//     type: 'script',
//     src: environment.BASE_URL +'/theme/vendors/dayjs/dayjs.min.js',
//     id: 'dayjs',
//     isLoad: false,
//     isHead: false
//   },
//   {
//     type: 'script',
//     src:environment.BASE_URL + '/theme/js/phoenix.js',
//     id: 'phoenix',
//     isLoad: true,
//     isHead: false
//   },
//   // Add more assets as needed
// ];
