import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { fabric } from 'fabric';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { canvasContext } from './Editor';
import { handleScaling, wordWrap } from '../../helper/helpToAutosizeTextbox';

const PreviwEditor = () => {
  const { setisAddBar, setCanvasHeight, canvasContainerRef, isChangeBarOpacity, selectedBarOpacity, selectedBarColor, isChangeBarColor, bars, setBars, isAddBar, selectedBarId, setSelectedBarId, isRemoveBar, fontSizeDescription, fontSize, canvasWidth, canvasHeight, metaInfo, isDreggable, TypeOption, sizeOption, isLoading, selectedFont, selectedFontDescription } = useContext(canvasContext);

  const canvasRef = useRef(null);
  // const canvasContainerRef = useRef(null)
  const [canvas, setCanvas] = useState(null);
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [imageFormat, setImageFormat] = useState('png'); // Default Type

  // Create A New Canvas Element
  useEffect(() => {
    fabric.devicePixelRatio = 2.5;
    const canvas = new fabric.Canvas('canvas', {
      // width: canvasContainerRef.current.offsetWidth,
      // height: canvasHeight,
      enableRetinaScaling: true, // Enable high-DPI scaling
      canvasScale: 2, // Adjust this value as needed
    });

    const handleOutsideCanvasClick = (e) => {
      const canvasElement = canvasContainerRef.current;
      const clickTarget = e.target;

      // Check if the click target is the canvas or one of its descendants
      if (!canvasElement?.contains(clickTarget)) {
        // Deselect any selected object in the canvas
        canvas.discardActiveObject();
        canvas.renderAll();
      }
    };

    // Listen for the 'selection:created' event
    canvas.on('selection:created', (e) => {
      const selectedObjects = e.selected; // Array of selected objects
      selectedObjects.forEach((selectedObject) => {
        selectedObject.set({
          borderColor: 'black',
          cornerColor: 'green',
          cornerSize: 7,
          transparentCorners: false
        });
        canvas.setActiveObject(selectedObject);
        canvas.renderAll(); // Render the canvas to apply the changes
      });
    });
    canvas.on('selection:updated', (e) => {
      const selectedObjects = e.selected; // Array of selected objects
      selectedObjects.forEach((selectedObject) => {
        selectedObject.set({
          borderColor: 'black',
          cornerColor: 'green',
          cornerSize: 7,
          transparentCorners: false
        });
        canvas.setActiveObject(selectedObject);
        canvas.renderAll(); // Render the canvas to apply the changes
      });
    });

    // Listen for the 'canvas Width size' event
    setCanvasHeight(canvasContainerRef.current.offsetWidth)
    const resizeCanvas = () => {
      const wrapperWidth = canvasContainerRef.current.offsetWidth;
      canvas.setWidth(wrapperWidth);
      canvas.setHeight(wrapperWidth);
      canvas.renderAll();
    };
    // Initial resize
    resizeCanvas();
    setCanvas(canvas);
    document.addEventListener('click', handleOutsideCanvasClick);
    // Attach event listener to window resize
    window.addEventListener('resize', resizeCanvas);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleOutsideCanvasClick);
      window.removeEventListener('resize', resizeCanvas);
      canvas && canvas.dispose();
    };
  }, []);

  useEffect(() => {
    if (canvas !== null) {
      // Update the canvas dimensions
      canvas && canvas?.setDimensions({
        // width: canvasWidth, 
        width: canvasContainerRef.current.offsetWidth,
        height: canvasHeight
      });

      // You may need to adjust the position of objects based on new dimensions
      canvas?.getObjects().forEach((obj) => {
        // Adjust object positions here
      });
      // setCanvasHeight(pri)
      canvas.renderAll();
    }
  }, [canvasHeight, canvasWidth]);

  useEffect(() => {
    if (canvas && metaInfo.image !== null && metaInfo.image.includes('base64')) {
      fabric.Image.fromURL(metaInfo.image, img => {
        // Clear existing image objects from the canvas
        canvas.getObjects('image').forEach((obj) => {
          canvas.remove(obj);
        });
        // Scale the image to fit the width of the canvas
        // Add the new image to the canvas
        img.scaleToWidth(canvas.width / 2); // Scale to half the canvas width
        // If the height of the image now exceeds the height of the canvas,
        // scale it to fit the height of the canvas
        if (img.getScaledHeight() > canvas.height) {
          img.scaleToHeight(canvas.height);
        }
        img.set({ left: 0, top: 0 });
        setImage(img);
        canvas.add(img);
      }, { crossOrigin: 'anonymous' });
      canvas.renderAll();
    }
  }, [metaInfo.image])

  // Load the canvas
  useEffect(() => {
    if (isLoading) {
      canvas.clear();
    }
  }, [isLoading])

  // Canvas Handler
  useEffect(() => {
    if (!canvas) return;
    if (canvas) {
      if (isDreggable.image && !image) {
        fabric.Image.fromURL(metaInfo.image, img => {
          // img.scale(0.5);
          // Scale the image to fit the width of the canvas
          img.scaleToWidth(canvas.width / 2); // Scale to half the canvas width
          // If the height of the image now exceeds the height of the canvas,
          // scale it to fit the height of the canvas
          if (img.getScaledHeight() > canvas.height) {
            img.scaleToHeight(canvas.height);
          }
          img.set({ left: 0, top: 0 });
          setImage(img);
          canvas.add(img);
        }, { crossOrigin: 'anonymous' });
      } else if (!isDreggable.image && image) {
        canvas.remove(image);
        setImage(null);
      }

      if (isDreggable.logo && !logo) {
        fabric.Image.fromURL(metaInfo.logo, logo => {
          // logo.scale(0.2);
          // Scale the image to fit the width of the canvas
          logo.scaleToWidth(canvas.width / 2); // Scale to half the canvas width
          // If the height of the image now exceeds the height of the canvas,
          // scale it to fit the height of the canvas
          if (logo.getScaledHeight() > canvas.height) {
            logo.scaleToHeight(canvas.height);
          }
          logo.set({ left: 0, top: 0 });
          setLogo(logo);
          canvas.add(logo);
        }, { crossOrigin: 'anonymous' });
      } else if (!isDreggable.logo && logo) {
        canvas.remove(logo);
        setLogo(null);
      }

      if (isDreggable.title && !title) {
        const titleText = wordWrap(metaInfo.title, canvas.width - 40);
        const title = new fabric.Textbox(titleText, {
          left: 10,
          top: 10,
          width: canvas.width - 40, // Initial width based on canvas width
          fontSize: Number(fontSize?.value),
          fontFamily: selectedFont?.value,
          editable: false,
          hasControls: true
        });
        setTitle(title);
        canvas.add(title);
        const handleScalings = handleScaling(title)
        title.on('scaling', handleScalings);
        canvas.renderAll()
      } else if (isDreggable.title && title) {
        // const titleText = wordWrap(metaInfo.title, 40);
        title.text = metaInfo.title;
        title.fontSize = Number(fontSize?.value);
        title.fontFamily = selectedFont?.value;
        const handleScalings = handleScaling(title)
        title.on('scaling', handleScalings);
        canvas.renderAll()
      } else if (!isDreggable.title && title) {
        canvas.remove(title);
        setTitle(null);
      }

      if (isDreggable.description && !description) {
        const descriptionText = wordWrap(metaInfo.description, canvas.width - 50);
        const description = new fabric.Textbox(descriptionText, {
          left: 10, // Small offset from the left
          top: 20, // Position below the title with a small gap
          width: canvas.width - 50, // Initial width based on canvas width
          fontSize: Number(fontSizeDescription?.value), // Limit the width to the canvas size with small offset
          fontFamily: selectedFontDescription?.value,
          editable: false,
          hasControls: true
        });
        setDescription(description);
        canvas.add(description);
        const handleScalings = handleScaling(description)
        description.on('scaling', handleScalings);
        canvas.renderAll()
      } else if (isDreggable.description && description) {
        // const descriptionText = wordWrap(metaInfo.description, 50);
        description.text = metaInfo.description;
        description.fontSize = Number(fontSizeDescription?.value)
        description.fontFamily = selectedFontDescription?.value;
        // canvas.renderAll();
        const handleScalings = handleScaling(description)
        description.on('scaling', handleScalings);
        canvas.renderAll()
      } else if (!isDreggable.description && description) {
        canvas.remove(description);
        setDescription(null);
      }

      if (isDreggable.color) {
        canvas.setBackgroundColor(metaInfo.color, () => {
          canvas.renderAll();
        });
      } else {
        canvas.setBackgroundColor(null, () => {
          canvas.renderAll();
        });
      }
    }
    return () => canvas && canvas

  }, [isDreggable.image, isDreggable.logo, isDreggable.title, isDreggable.description, isDreggable.color, metaInfo, selectedFont, selectedFontDescription, fontSizeDescription, fontSize]);

  // Image Download Handler
  const downloadImage = () => {
    if (canvas) {
      const reSize = sizeOption !== null ? sizeOption.value.split('By') : canvasWidth;
      // user selected height and width
      let width = reSize?.length ? reSize[0].split('px')[0] : canvasWidth;
      let height = reSize?.length ? reSize[1].split('px')[0] : canvasHeight;

      // Create a temporary canvas with the desired size (1080x1080)
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = width;
      tempCanvas.height = height;

      const tempContext = tempCanvas.getContext('2d');

      // manage image type (jpeg or png)
      if (isDreggable.color && TypeOption?.value !== null && TypeOption?.value === 'jpeg') tempContext.fill = metaInfo.color;
      if (isDreggable.color && TypeOption?.value !== null && TypeOption?.value === 'png') tempContext.fill = metaInfo.color;
      if (!isDreggable.color && TypeOption?.value !== null && TypeOption?.value === 'jpeg') tempContext.fill = '#FFFFFF';

      // Calculate the scaling factors to fit the original content into the new canvas
      const scaleX = width / canvas.width;
      const scaleY = height / canvas.height;

      // const scale = Math.min(scaleX, scaleY);
      // canvas.current.width = canvas.width * scale
      // canvas.current.height = canvas.height * scale

      // Draw the original canvas content onto the temporary canvas at the desired size
      tempContext.drawImage(canvasRef.current, 0, 0, canvas.width * scaleX, canvas.height * scaleY);

      // Convert the temporary canvas to an image URL
      const imageUrl = tempCanvas.toDataURL(TypeOption?.value || imageFormat, 1.0);
      const words = title?.text && title.text.split(' ').length >= 2 ? title.text.split(' ').slice(0, 2).join('-') : title?.text
      // Trigger the download of the image
      if (isDreggable.title || isDreggable.description || isDreggable.image || isDreggable.logo || isDreggable.color) {
        saveAs(imageUrl, `${title?.text ? words + Math.floor(Math.random() * 1000) * 500 : 'MetaSnap' + Math.floor(Math.random() * 1000) * 1000}.${TypeOption?.value || imageFormat}`);
      }
    }
  };

  // generate bar id
  const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  // Create a ref to keep track of the selected bar
  const selectedBarRef = useRef(null);
  // add bar functionality
  useEffect(() => {
    const handleAddBarClick = () => {
      if (canvas) {
        const randomX = Math.random() * (canvas.getWidth() - 200);
        const randomY = Math.random() * (canvas.getHeight() - 100);
        const bar = new fabric.Rect({
          left: randomX,
          top: randomY,
          width: 200,
          height: 100,
          opacity: 1,
          fill: '#FFFFFF',
          selectable: true,
          hasControls: true,
          hasBorders: true,
          stroke: 'transparent',
          strokeWidth: 1,
          borderColor: 'transparent'
        });

        // Assign a random ID to the bar object
        const randomId = generateRandomId();
        bar.id = randomId;

        // Deselect previously selected bar, if any
        if (selectedBarRef.current) {
          selectedBarRef.current.set({
            hasBorders: true,
            borderColor: 'transparent'
          });
          selectedBarRef.current = null;
        }

        canvas.add(bar);
        canvas.setActiveObject(bar); // Select the newly added bar
        canvas.renderAll();
        setBars(prevBars => [...prevBars, bar]);

        // Store the reference to the selected bar
        selectedBarRef.current = bar;
      }
    };

    if (isAddBar) {
      handleAddBarClick();
      setisAddBar(!isAddBar); // Reset the add bar flag
    }
  }, [isAddBar]);

  // add remove bar functionality
  useEffect(() => {
    const handleRemoveSelectedBarClick = () => {
      if (canvas && selectedBarId) {
        const selectedBar = bars.find(bar => bar.id === selectedBarId);
        if (selectedBar) {
          canvas.remove(selectedBar);
          setBars(prevBars => prevBars.filter(bar => bar !== selectedBar));
          setSelectedBarId(null);
        }
      }
    };
    handleRemoveSelectedBarClick();
  }, [isRemoveBar, setSelectedBarId])

  // change bar bg color functionality
  useEffect(() => {
    const changeBarColor = () => {
      if (canvas && selectedBarId && selectedBarColor) {
        const updatedBars = bars.map((bar) =>
          bar.id === setSelectedBarId ? { ...bar, fill: selectedBarColor } : bar
        );
        setBars(updatedBars);
        const barToChangeColor = bars.find(bar => bar.id === selectedBarId);
        if (barToChangeColor) {
          barToChangeColor.set({ fill: selectedBarColor, stroke: selectedBarColor });
          canvas.renderAll();
        }
      }
    };
    changeBarColor();
  }, [isChangeBarColor, setSelectedBarId])


  // change bar Opacity functionality
  useEffect(() => {
    const changeBarOpacity = () => {
      if (canvas && selectedBarId && selectedBarColor) {
        const updatedBars = bars.map((bar) =>
          bar.id === setSelectedBarId ? { ...bar, opacity: selectedBarOpacity } : bar
        );
        setBars(updatedBars);
        const selectedBar = bars.find(bar => bar.id === selectedBarId);
        if (selectedBar) {
          selectedBar.set('opacity', parseFloat(selectedBarOpacity).toFixed(2)).setCoords();
          canvas.renderAll();
        }
      }
    };
    changeBarOpacity();
  }, [isChangeBarOpacity, setSelectedBarId])
  return (
    <div className='col-lg-5 col-xl-5 col-md-4 d-lg-block'>
      <div className="card mb-5" ref={canvasContainerRef}>
        <canvas id="canvas" ref={canvasRef} style={{ borderRadius: '0.5rem', height: canvasHeight }} />
      </div>
      <div className='w-100 text-center my-5'>
        <button className="btn btn-soft-primary me-1 mb-1" type="button" onClick={() => downloadImage()}><FontAwesomeIcon icon={faDownload} />  Save Image</button>
      </div>
    </div>
  )
}

export default PreviwEditor
