import {toastifyType} from '../../constants';



export const addTost = ({type, message}) => {
    return {
      type: toastifyType.SHOW_TOAST,
      payload: { type, message },
    };
  };
  