//API Base URL
export const apiBaseURL = {
    USER_REGISTER: "/auth/register",
    USER_LOGIN: "/auth/login",
};

export const authActionType = {
    LOGIN_USER: 'LOGIN_USER',
    LOGOUT: 'LOGOUT',
    REGISTER: 'REGISTER'
};

export const Tokens = {
    USER_TOKEN: 'auth_token',
    USER: 'user'
};


export const errorMessage = {
    TOKEN_NOT_PROVIDED: 'Token not provided',
    TOKEN_EXPIRED: 'Token has expired',
    TOKEN_INVALID: 'Could not decode token: Error while decoding to JSON: Syntax error',
    TOKEN_INVALID_SIGNATURE: 'Token Signature could not be verified.'
};

export const constants = {
    SET_TOTAL_RECORD: 'SET_TOTAL_RECORD',
    IS_LOADING: 'IS_LOADING',

};

export const toastifyType = {
    SHOW_TOAST: 'SHOW_TOAST',
    SHOW_SUCCESS_MESSAGE: 'SHOW_SUCCESS_MESSAGE',
    SHOW_ERROR_MESSAGE: 'SHOW_ERROR_MESSAGE'
}