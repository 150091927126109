import { authActionType } from '../../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case authActionType.REGISTER:
            return action.payload;
        case authActionType.LOGIN_USER:
            return action.payload;
        // case toastType.ERROR:
        //     return {...state, errorMessage : action.payload}
        default:
            return state;
    }
};
