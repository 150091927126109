import { faCirclePlus, faCloudArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Select from 'react-select';
import { canvasContext } from './Editor';
import noImage from './../../assets/images/noImage.png'

const HandleEditor = () => {
    const { callBackchangeBarOpacity, callBackchangeBarColor, bars, callBackIsAddBar, callBackIsRemoveBar, handleFontSizeDescriptionChange, fontSizeDescription, fontSizeOptions, handleFontSizeChange, fontSize, selectedFontDescription, handleFontChangesetDescription, selectedFont, handleFontChange, availableFonts, setMetaInfo, heandelInput, heandelIsDreggableInput, isDreggable, metaInfo, sizeOption, options, handleChangeSize, TypeOption, handleChangeType, TypeOptions } = useContext(canvasContext);
    // Function to handle image preview
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        // Check if the file is of jpeg or png format
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setMetaInfo(input => ({ ...input, image: reader.result }))
                // setIsDreggable(input => ({ ...input, image: true}))
            };

            reader.readAsDataURL(file);
        } else {
            // Invalid file format, show an error or perform some action
            console.log('Invalid file format. Please select a jpeg or png image.');
        }
    };
    // Function to handle logo preview
    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        // Check if the file is of jpeg or png format
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setMetaInfo(input => ({ ...input, logo: reader.result }))
            };

            reader.readAsDataURL(file);
        } else {
            // Invalid file format, show an error or perform some action
            console.log('Invalid file format. Please select a jpeg or png image.');
        }
    };
    return (
        <div className='col-lg-7 col-xl-7 col-md-8'>
            <div className="card d-flex flex-column mb-5">
            <Card.Body className='m-2 handel_scs'>
                <Row>
                    {/* size */}
                    <Col xs={6} md={6}>
                        <div><span className=' ms-5 h6 fw-bold'>Size: </span></div>
                    </Col>
                    <Col xs={6} md={6} className='mb-3'>
                        <Select
                            isSearchable={false}
                            defaultValue={options[0]}
                            placeholder={"Select Size..."}
                            value={sizeOption}
                            onChange={handleChangeSize}
                            options={options}
                        />
                    </Col>
                    {/* Type */}
                    <Col xs={6} md={6}>
                        <div><span className='ms-5 h6 fw-bold'>Type: </span></div>
                    </Col>
                    <Col xs={6} md={6} className='mb-3'>
                        <Select
                            isSearchable={false}
                            placeholder={"Select Type..."}
                            value={TypeOption}
                            onChange={handleChangeType}
                            options={TypeOptions}
                        />
                    </Col>
                    {/* color */}
                    <Col xs={6} md={6}>
                        <input
                            type="checkbox"
                            name="color"
                            id="checkbox1"
                            className="p-3 me-4 "
                            checked={isDreggable.color}
                            onChange={(e) => heandelIsDreggableInput(e)}
                        /> <span className='fw-bold h6'>Background Colour: </span>
                    </Col>
                    <Col xs={6} md={6} className='mb-3'>
                        <input
                            className='p-0 w-100'
                            type="color"
                            name="color"
                            value={metaInfo.color || ''}
                            style={{height:'40px'}}
                            onChange={(e) => heandelInput(e)} />
                    </Col>
                    {/* title */}
                    <Col xs={6} md={6}>
                        <input
                            type="checkbox"
                            name="title"
                            id="checkbox1"
                            className="p-3 me-4"
                            checked={isDreggable.title}
                            onChange={(e) => heandelIsDreggableInput(e)}
                        /> <span className='fw-bold h6'>Title: </span>
                    </Col>
                    <Col xs={6} md={6} className='mb-3'>
                        <div className='d-flex mb-2'>
                            <div className='w-75'>
                                <Select
                                    isSearchable={false}
                                    defaultValue={availableFonts[0]}
                                    placeholder={"Select Font..."}
                                    value={selectedFont}
                                    onChange={(e) => handleFontChange(e)}
                                    options={availableFonts}
                                />
                            </div>
                            <div className='w-50'>
                                <Select
                                    isSearchable={false}
                                    defaultValue={fontSizeOptions[0]}
                                    placeholder={"Select Size..."}
                                    value={fontSize}
                                    onChange={(e) => handleFontSizeChange(e)}
                                    options={fontSizeOptions}
                                />
                            </div>
                        </div>
                        <div>
                            <input
                                type="text"
                                id="input1"
                                required
                                name='title'
                                value={metaInfo.title || ''}
                                // placeholder="Enter Your Text Here ..."
                                className="p-2 w-100"
                                onChange={(e) => heandelInput(e)}
                            />
                        </div>
                    </Col>
                    {/* description */}
                    <Col xs={6} md={6}>
                        <input
                            type="checkbox"
                            name="description"
                            id="checkbox1"
                            className="p-3 me-4"
                            checked={isDreggable.description}
                            onChange={(e) => heandelIsDreggableInput(e)}
                        /> <span className='fw-bold h6'>Description: </span>
                    </Col>
                    <Col xs={6} md={6} className='mb-3'>
                        <div className='d-flex mb-2'>
                            <div className='w-75'>
                                <Select
                                    isSearchable={false}
                                    defaultValue={availableFonts[0]}
                                    placeholder={"Select Font..."}
                                    value={selectedFontDescription}
                                    onChange={(e) => handleFontChangesetDescription(e)}
                                    options={availableFonts}
                                />
                            </div>
                            <div className='w-50'>
                                <Select
                                    isSearchable={false}
                                    defaultValue={fontSizeOptions[0]}
                                    placeholder={"Select Size..."}
                                    value={fontSizeDescription}
                                    onChange={(e) => handleFontSizeDescriptionChange(e)}
                                    options={fontSizeOptions}
                                />
                            </div>
                        </div>
                        <div>
                            <textarea
                                type="text"
                                id="input1"
                                required
                                name='description'
                                value={metaInfo.description || ''}
                                // placeholder="Enter Your Text Here ..."
                                className="p-2 w-100"
                                onChange={(e) => heandelInput(e)}
                            />
                        </div>
                    </Col>
                    {/* image  */}
                    <Col xs={6} md={6}>
                        <input
                            type="checkbox"
                            name="image"
                            id="checkbox1"
                            className="p-3 me-4 "
                            checked={isDreggable.image}
                            onChange={(e) => heandelIsDreggableInput(e)}
                        /> <span className='fw-bold h6'>Image: </span>
                    </Col>
                    <Col xs={6} md={6} className='mb-3'>
                        {/* {metaInfo.image && <img src={metaInfo.image} alt="image" height={50} width={50} />} */}

                        {/* {metaInfo.image && <img src={metaInfo.image} alt="image" height={50} width={50} />} */}
                        <div className='d-flex' >
                            <div style={{ height: '150px', width: '150px', border: '1px solid silver', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                            // className={`${!metaInfo.image && }`}
                            >
                                {<img src={metaInfo.image ? metaInfo.image : noImage} alt="image" style={{ width: '100%' }} className={`${!metaInfo.image && 'h-100'}`}/>}
                            </div>

                            <div className="upload-btn-wrapper px-3">
                                <FontAwesomeIcon className='text-info' icon={faCloudArrowUp} />
                                {/* <button className="btn">Upload a file</button> */}
                                <input type="file" accept=".jpeg, .jpg, .png" name="myfile" onChange={(e) => handleImageChange(e)} />
                            </div>
                        </div>
                    </Col>
                    {/* Logo  */}
                    <Col xs={6} md={6}>
                        <input
                            type="checkbox"
                            name="logo"
                            id="checkbox1"
                            disabled={metaInfo.logo === '' || metaInfo.logo === null ? true : false}
                            className="p-3 me-4 "
                            checked={isDreggable.logo}
                            onChange={(e) => heandelIsDreggableInput(e)}
                        /> <span className='fw-bold h6'>Logo: </span>
                    </Col>
                    <Col xs={6} md={6}>
                        {/* {metaInfo.image && <img src={metaInfo.image} alt="image" height={50} width={50} />} */}
                        <div className='d-flex' >
                            <div style={{ height: '100px', width: '100px', border: '1px solid silver', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {<img src={metaInfo.logo === '' || metaInfo.logo === null ? noImage : metaInfo.logo } alt="logo" style={{  width: '100%' }} className={`${metaInfo.logo === '' || metaInfo.logo === null && 'h-100'}`} />}
                            </div>

                            <div className="upload-btn-wrapper px-3">
                                <FontAwesomeIcon className='text-info' icon={faCloudArrowUp} />
                                {/* <button className="btn">Upload a file</button> */}
                                <input type="file" name="myfile" accept=".jpeg, .jpg, .png" onChange={(e) => handleLogoChange(e)} />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={12}>
                        {/* {metaInfo.image && <img src={metaInfo.image} alt="image" height={50} width={50} />} */}
                        <Row className='py-3' style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            // justifyContent: 'space-around',
                            alignItems: 'start'
                        }}>
                            <Col xs={2} className="d-flex align-items-center justify-content-evenly p-0 mt-3" style={{width: 'fit-content'}}>
                                <FontAwesomeIcon className='text-success fs-2 mx-4' onClick={callBackIsAddBar} icon={faCirclePlus} /><span className='fw-bold text-nowrap h6'>Add bar: </span>
                            </Col>
                            {bars && bars?.length === 0 &&
                                <Col xs={9} className="p-0 mt-3">
                                    {/* <div style={{ display: 'flex', alignItems: 'start', marginBottom: '10px', justifyContent: 'space-between' }}>
                                        <div className='fw-bold fs-6' style={{ width: '200px', textAlign: 'center', marginRight: '20px' }}>Bar</div>
                                        <div className='fw-bold fs-6'>Color</div>
                                        <div className='fw-bold fs-6'>Delete</div>
                                    </div> */}
                                    <div className='' style={{ display: 'flex', alignItems: 'start', marginBottom: '10px', justifyContent: 'end' }}>
                                        <div style={{ borderRadius: '5px', width: '220px', height: '30px', backgroundColor: '#FFFFFF', border: '1px solid silver' }}></div>
                                        <div>
                                            {/* <input type='text' style={{ width: '40px', height: '30px', padding: 0, outline: 'none' }} /> */}
                                        </div>
                                        <div>
                                            <input
                                                style={{ height: '30px', width: '30px', padding: 0, borderRadius: '5px', border: '1px solid silver', outline: 0 }}
                                                className='border-0 mx-2'
                                                type="color"
                                                name="color"
                                                disabled={true}
                                                value={'#FFFFFF'}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                style={{ height: '30px', width: '40px', padding: 0, borderRadius: '5px', border: '1px solid silver', outline: 0 }}
                                                type="text" // Use text input type
                                                step="0.01"
                                                min="0"
                                                max="1"
                                                className='mx-2'
                                                disabled={true}
                                            // value={Number(bar.opacity).toFixed(2) || '1.00'}
                                            // onChange={(e) => callBackchangeBarOpacity(e, bar.id)}
                                            />
                                        </div>
                                        <div>
                                            <FontAwesomeIcon className='text-danger fs-2' icon={faTrash} />
                                        </div>
                                    </div>
                                </Col>}
                            <Col xs={9} className='p-0 mt-3'>
                                {/* {bars && bars?.length >= 1 && <div style={{ display: 'flex', alignItems: 'start', marginBottom: '10px', justifyContent: 'space-between' }}>
                                    <div className='fw-bold fs-6' style={{ width: '200px', textAlign: 'center', marginRight: '20px' }}>Bar</div>
                                    <div className='fw-bold fs-6'>Color</div>
                                    <div className='fw-bold fs-6'>Delete</div>
                                </div>} */}
                                {bars.map(bar => (
                                    <div key={bar.id} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px', justifyContent: 'end' }} >
                                        <div style={{ borderRadius: '5px', width: '220px', height: '30px', backgroundColor: bar.fill, border: '1px solid silver' }}></div>
                                        <div>
                                            <input
                                                style={{ height: '30px', width: '30px', padding: 0, borderRadius: '5px' }}
                                                className='border-0 mx-2'
                                                type="color"
                                                name="color"
                                                value={bar.fill || ''}
                                                onChange={(e) => callBackchangeBarColor(e, bar.id)} />
                                        </div>
                                        <div>
                                            <input
                                                style={{ height: '30px', width: '50px', padding: 0, borderRadius: '5px', border: '1px solid silver', outline: 0 }}
                                                type="number"
                                                step="0.01"
                                                min="0"
                                                max="1"
                                                className='me-2'
                                                value={Number(bar.opacity).toFixed(2) || '1.00'}
                                                onChange={(e) => callBackchangeBarOpacity(e, bar.id)} />
                                        </div>

                                        <div>
                                            <FontAwesomeIcon className='text-danger fs-2' onClick={() => callBackIsRemoveBar(bar.id)} icon={faTrash} />
                                        </div>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
            </div>

        </div>
    )
}

export default HandleEditor