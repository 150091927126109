
import { Tokens, apiBaseURL, authActionType, toastifyType } from '../../constants';
import apiConfig from './../../config/apiConfig';
import { setLoading } from "./loadingAction";
import { addTost } from './toastActions';
export const register = (user, navigate) => async (dispatch) => {
    dispatch(setLoading(true))
    await apiConfig.post(apiBaseURL.USER_REGISTER, user).then((response) => {
        console.log("User registration", response.data.data)
        dispatch({ type: authActionType.REGISTER, payload: response.data.data });
        dispatch(setLoading(false))
        navigate('/login')
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
    }).catch(({ response }) => {
        setLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: response?.data?.message }))
    });
};

export const login = (user, navigate) => async (dispatch) => {
    dispatch(setLoading(true))
    await apiConfig.post(apiBaseURL.USER_LOGIN, user).then((response) => {
        localStorage.setItem(Tokens.USER_TOKEN, JSON.stringify(response.data.data.token))
        localStorage.setItem(Tokens.USER, JSON.stringify(response.data.data.user))
        dispatch({ type: authActionType.LOGIN_USER, payload: response.data.data });
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        setTimeout(() => {
            window.location.reload();
        }, 1000);
        // navigate('/')
    }).catch(({ response }) => {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: response?.data?.message }))
    });
};



export const logout = (navigate) => async (dispatch) => {
    // await apiConfig.post(apiBaseURL.USER_LOGIN).then((response) => {
        localStorage.removeItem(Tokens.USER_TOKEN)
        localStorage.removeItem(Tokens.USER)
        // dispatch({ type: authActionType.LOGIN, payload: response.data.data });
        dispatch(setLoading(false))
        navigate('/login')
    // }).catch(({ response }) => {
    //     setLoading(false)
    //     console.log('error', response);
    // });
};
