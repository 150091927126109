import './assets/theme/css/simplebar.css'
import './assets/theme/css/theme.css'
import './assets/theme/css/user.css'
import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import ForgotPassword from './components/auth/ForgotPassword';
import './assets/style/index.scss'
import { Tokens } from './constants';
import UserApp from './UserApp';
import { useDispatch } from 'react-redux';
// import { cssHendling } from './cssHendling/cssHendling';

const App = () => {
  const token = localStorage.getItem(Tokens.USER_TOKEN)
 
  return (
    <div style={{minHeight: '100vh'}}>
      <Routes>
        <Route path='/login' element={<SignIn />} />
        <Route path='/register' element={token !== null ? <Navigate replace to={'/editor'} /> : <SignUp />} />
        <Route path='/forgot-password' element={token !== null ? <Navigate replace to={'/editor'} /> : <ForgotPassword />} />
        <Route path='/*' element={<UserApp />} />
        <Route path='/' element={token ? <Navigate replace to={'/editor'} /> : <Navigate replace to={'/login'} />} />
        <Route path='*' element={<Navigate replace to={"/"} />} />
      </Routes>
    </div>
  );
}

export default App;
