import { Tokens, errorMessage } from '../constants';
import { environment } from './environment'

export default {
    setupInterceptors: (axios, isFormData = false) => {
        axios.interceptors.request.use((config) => {
            let isToken = localStorage.getItem(Tokens.USER_TOKEN);
            if (isToken) {
                return config;
            }
            if (isToken) {
                config.headers['Authorization'] = `Bearer ${isToken}`;
            }
            if (!isToken) {
                if (!window.location.href.includes('login')
                    && !window.location.href.includes('register')) {
                    window.location.href = environment.BASE_URL + "/";
                }
            }
            if (isFormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            }
            return config;
        },
            (error) => {
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            response => successHandler(response),
            error => errorHandler(error)
        );
        const errorHandler = (error) => {
            console.log('error.response.status: ' + error.response.status)
            if (error.response.status === 401
                || error.response.data.message === errorMessage.TOKEN_NOT_PROVIDED
                || error.response.data.message === errorMessage.TOKEN_INVALID
                || error.response.data.message === errorMessage.TOKEN_INVALID_SIGNATURE
                || error.response.data.message === errorMessage.TOKEN_EXPIRED) {
                localStorage.removeItem(Tokens.USER_TOKEN);
                window.location.href = environment.BASE_URL + '/login';
            } else if (error.response.status === 403 || error.response.status === 404) {
                    window.location.href = environment.BASE_URL + '/editor';
            } else if (error.response.status === 500) {
                if (error.response.data.message.includes("Too Many Attempts.")) {
                    return Promise.reject({ ...error })
                } else {
                    localStorage.setItem("server_error", JSON.stringify(true))
                }
            } else {
                return Promise.reject({ ...error })
            }
        };
        const successHandler = (response) => {
            return response;
        };
    }
};
