import React, { useEffect } from 'react'
import MetaNavbar from './Navbar'
import SideBar from './SideBar';
import Footer from './Footer';
import { cssAssets } from '../../assetsHandel/cssAssets';

const MasterLayout = (props) => {
  useEffect(() => {
    cssAssets();
    return () => cssAssets();
  }, [])
  const { children } = props;
  return (
    <main className="main" id="top">
      <SideBar />
      <MetaNavbar />
      <div className="content">
        {/* <div className="d-flex flex-center content-min-h"> */}
        {children}
        {/* </div> */}
        <Footer />
      </div>
    </main>
  )
}

export default MasterLayout