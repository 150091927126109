
import Editor from "./components/editor/Editor";


export const route = [
    {
        path: "editor",
        ele: <Editor />,
        permission: ''
    },
]
