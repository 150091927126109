import React, { createContext, useEffect, useRef, useState } from 'react'
import MasterLayout from '../layout/MasterLayout'
import PreviwEditor from './PreviwEditor'
import HandleEditor from './HandleEditor'
import axios from 'axios'
import { environment } from '../../config/environment'


export const canvasContext = createContext(null);

const Editor = () => {
  const [isLoading, setIsLoading] = useState(false)
  // const [metadata, setMetadata] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(898); // Default width
  const [canvasHeight, setCanvasHeight] = useState(502) // Default Height
  const [canvasOffsetHeight, setCanvasOffsetHeight] = useState(502) // Default offset Height
  const [selectedFont, setSelectedFont] = useState({ value: 'Arial', label: 'Arial' });
  const [fontSize, setFontSize] = useState({ value: '20', label: '20' });
  const [fontSizeDescription, setFontSizeDescription] = useState({ value: '15', label: '15' });
  const [selectedFontDescription, setSelectedFontDescription] = useState({ value: 'Arial', label: 'Arial' });
  const [bars, setBars] = useState([]);
  const [isAddBar, setisAddBar] = useState(false)
  const [isRemoveBar, setisRemoveBar] = useState(false)
  const [isChangeBarColor, setisChangeBarColor] = useState(false)
  const [selectedBarId, setSelectedBarId] = useState(null);
  const [selectedBarColor, setSelectedBarColor] = useState('#FFFFFF');
  const [isChangeBarOpacity, setIsChangeBarOpacity] = useState(false)
  const [selectedBarOpacity, setSelectedBarOpacity] = useState('1.00')
  const canvasContainerRef = useRef(null)
  const [errors, setErrors] = useState({
    url: '',
  });


  const [metaInfo, setMetaInfo] = useState({
    title: "",
    description: "",
    image: null,
    logo: null,
    color: '#FFFFFF',
    url: ''
  })
  const [isDreggable, setIsDreggable] = useState({
    title: false,
    description: false,
    image: false,
    logo: false,
    color: false
  })
  const [sizeOption, setSizeOption] = useState({ value: '1080px By 1080px', label: '1080px By 1080px' })
  const [TypeOption, setTypeOption] = useState({ value: 'png', label: 'png' })

  useEffect(() => {
    if (isLoading) {
      setIsDreggable({
        title: false,
        description: false,
        image: false,
        logo: false,
        color: false
      })
      setBars([])
    }
  }, [isLoading])
  const options = [
    { value: '1080px By 1080px', label: '1080px By 1080px' },
    { value: '1080px By 1350px', label: '1080px By 1350px' },
  ];

  const TypeOptions = [
    { value: 'png', label: 'png' },
    { value: 'jpeg', label: 'jpeg' },
  ];
  // const availableFonts = [
  //     'Arial',
  //     'Helvetica',
  //     'Georgia',
  //     'Times New Roman',
  //     'Courier New',
  //     'Verdana',
  //     'Impact',
  //     'Comic Sans MS',
  //     // Add more custom fonts here
  // ];
  const availableFonts = [
    { value: 'Arial', label: 'Arial' },
    { value: 'Helvetica', label: 'Helvetica' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Times New Roman', label: 'Times New Roman' },
    { value: 'Courier New', label: 'Courier New' },
    { value: 'Verdana', label: 'Verdana' },
    { value: 'Impact', label: 'Impact' },
    { value: 'Comic Sans MS', label: 'Comic Sans MS' },
  ]

  const fontSizeOptions = [
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 25, label: 25 },
    { value: 30, label: 30 },
    { value: 40, label: 40 },
    { value: 55, label: 55 },
    { value: 75, label: 75 },
    { value: 80, label: 80 },
  ];

  const handleChangeSize = (selectedOption) => {
    setSizeOption(selectedOption)
    // setCanvasHeight(canvasContainerRef.current.offsetWidth)
   
    const reSize = selectedOption.value.split('By');
    // user selected height and width
    let width = reSize[0].split('px')[0];
    let height = reSize[1].split('px')[0]

    // const selectedWidth = width;
    // const selectedHeight = height;

    // const canvasAspectRatio = selectedWidth / selectedHeight;

    // const canvasHeights = canvasAspectRatio / canvasAspectRatio;
    // setCanvasWidth(canvasWidth)
    // setCanvasHeight(canvasHeights)

    // const selectedOption = sizeOption; // Assuming you have stored the selected option somewhere
    // const aspectRatio = selectedOption.value.split('By');
    // const width = parseInt(aspectRatio[0]);
    // const height = parseInt(aspectRatio[1]);
    const wrapperWidth = canvasContainerRef.current.offsetWidth;
    const newHeight = (height / width) * wrapperWidth;
    setCanvasWidth(wrapperWidth)
    setCanvasHeight(newHeight)

  };
  const handleChangeType = (selectedOption) => {
    setTypeOption(selectedOption)
  };

  const heandelInput = (e) => {
    const { name, value } = e.target
    setMetaInfo(input => ({ ...input, [name]: value }))
  }

  const heandelIsDreggableInput = (e) => {
    const { name, checked } = e.target
    setIsDreggable(input => ({ ...input, [name]: checked }))
  }

  // Apply the selected font to the input text
  const handleFontChange = (e) => {
    setSelectedFont(e);
  };

  // Apply the selected font to the input Description
  const handleFontChangesetDescription = (e) => {
    setSelectedFontDescription(e);
  };

  const handleFontSizeChange = (event) => {
    const newSize = parseInt(event.value);
    if (!isNaN(newSize) && newSize >= 1) {
      setFontSize({ label: newSize, value: newSize })
    }
  };

  const handleFontSizeDescriptionChange = (event) => {
    const newSize = parseInt(event.value);
    if (!isNaN(newSize) && newSize >= 1) {
      setFontSizeDescription({ label: newSize, value: newSize })
    }
  };

  const handleValidation = () => {
    let error = {};
    let isValid = false;
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    console.log('sadsa', metaInfo.url.match(urlRegex))
    console.log('sadsdsada', !metaInfo.url.match(urlRegex))
    if (metaInfo.url === '') {
      error['url'] = 'Please enter meta URL';
    } else if(!metaInfo.url.match(urlRegex)) {
      error['url'] = 'Please enter valid meta URL';
    } else {
      isValid = true;
    }
    setErrors(error);
    return isValid;
  };


  const fetchMetadata = async () => {
    try {
      if (handleValidation()) {
        setIsLoading(true)
        // Replace '/api/fetch-metadata' with your actual API endpoint
        const { data } = await axios.post(environment.SERVER_URL+'/api/snap', {
          URL: metaInfo.url
        });
        // setMetadata(response.data);
        if (data) {
          setMetaInfo({
            title: data?.data?.title,
            description: data?.data?.description,
            image: data?.data?.image,
            logo: data?.data?.logo,
            color: '#FFFFFF',
            url: data?.data?.url
          })
          setIsLoading(false)
        }
      }
    } catch (error) {
      console.error('Failed to fetch metadata', error);
      setIsLoading(false)
    }
  };

  const callBackIsAddBar = () => {
    setisAddBar(!isAddBar)
  }
  const callBackIsRemoveBar = (id) => {
    setSelectedBarId(id);
    setisRemoveBar(!isRemoveBar)
  };

  const callBackchangeBarColor = (e, id) => {
    const { value } = e.target
    setSelectedBarId(id);
    setisChangeBarColor(!isChangeBarColor)
    setSelectedBarColor(value)
  }

  const callBackchangeBarOpacity = (e, id) => {
    const { value } = e.target
    setSelectedBarId(id);
    setIsChangeBarOpacity(!isChangeBarOpacity)
    setSelectedBarOpacity(value)
    if (isNaN(value) || value < 0 || value > 1) {
      setSelectedBarOpacity(1.00)
    }
  }
  return (
    <MasterLayout>
      <div id='editor'>
        <div className="needs-validation">
          <div className="input-group mb-3 m-auto w-100 ">
            <input 
            className={`form-control  ${errors['url'] && 'is-invalid'}`}  id="url"
            type="text" placeholder="Enter URL" aria-label="Enter URL"  name="url"
              onChange={(e) => heandelInput(e)}
              value={metaInfo.url} required=""
            />
            <button className="btn input-group-btn  btn-outline-primary rounded-end" type="button" disabled={isLoading} onClick={() => fetchMetadata()} >{isLoading && <div className="spinner-border spinner-border-sm" style={{width: '15px', height: '15px'}} role="status">
              <span className="sr-only"></span>
            </div>}
              {isLoading ? 'Please wait...' : 'Generate Preview'}</button>
              <div className={errors['url'] ? "invalid-feedback" : ''}>{errors['url'] ? errors['url'] : null}</div>
          </div>
        </div>
        <div className='row gy-3 gx-5 gx-xxl-6'>
          <canvasContext.Provider value={{ setisAddBar, setCanvasHeight, canvasContainerRef, isChangeBarOpacity, selectedBarOpacity, setIsDreggable, selectedBarColor, isChangeBarColor, bars, setBars, setSelectedBarId, selectedBarId, isAddBar, isRemoveBar, canvasHeight, canvasWidth, isLoading, metaInfo, fontSizeDescription, fontSize, selectedFont, selectedFontDescription, isDreggable, TypeOption, sizeOption, callBackchangeBarOpacity, callBackchangeBarColor, callBackIsAddBar, callBackIsRemoveBar, handleFontChange, availableFonts, fontSizeOptions, handleFontSizeDescriptionChange, handleFontSizeChange, handleFontChangesetDescription, setMetaInfo, heandelInput, heandelIsDreggableInput, options, handleChangeSize, handleChangeType, TypeOptions }}>
            <PreviwEditor />
            <HandleEditor />
          </canvasContext.Provider>
        </div>
      </div>
    </MasterLayout>
  )
}

export default Editor