import { combineReducers } from 'redux';
import authReducer from './authReducer';
import loadingReducer from './loadingReducer';
import toastReducer from './toastReducer';

const rootReducer = combineReducers({
  loginUser: authReducer,
  isLoading: loadingReducer,
  toast: toastReducer,
});

export default rootReducer;