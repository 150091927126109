import React from 'react'
import { Link } from 'react-router-dom'

const SideBar = () => {
  const isActive = window.location.pathname.includes('editor')
  return (
    <nav className="navbar navbar-vertical navbar-expand-lg">
      <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
        {/* scrollbar removed*/}
        <div className="navbar-vertical-content">
          <ul className="navbar-nav flex-column" id="navbarVerticalNav">
            <li className="nav-item">
              {/* label*/}
              <p className="navbar-vertical-label">App</p>
              <hr className="navbar-vertical-line" />
              {/* parent pages*/}
              <div className="nav-item-wrapper">
                <Link
                  className={`nav-link ${isActive && 'active'} label-1`}
                  to="/editor"
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <span data-feather="life-buoy" />
                    </span>
                    <span className="nav-link-text-wrapper">
                      <span className="nav-link-text">Editor</span>
                    </span>
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-vertical-footer">
        <button className="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center">
          <span className="uil uil-left-arrow-to-left fs-0" />
          <span className="uil uil-arrow-from-right fs-0" />
          <span className="navbar-vertical-footer-text ms-2">Collapsed View</span>
        </button>
      </div>
    </nav>
  )
}

export default SideBar
